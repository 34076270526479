// src/components/Home.js
import React from 'react';

const Home = () => {
    return <div className="flex flex-col  flex-initial self-start justify-center items-left h-screen">
        <div className="text-custom-80 text-balance justify-between font-black font-sans flex items-center">
            <div className='grow'> <span className='grow'>Hi, I'm Ethan.</span>
                    <div className='font-semibold text-lg'>A rising junior at Penn, studying Computer Science, Math, and Robotics.</div>
                    <div className='font-semibold text-lg'>Avid coder, musician, problem-solver.</div>
</div>
                   
                    <img src={'/Ethan.jpeg'} alt="Ethan" className="ml-4 w-40 h-40 object-cover rounded-lg" />
                </div>
        <div className='h-4'></div>
        <div className='font-medium text-lg'>Summer 2024, I worked on a joint project between the <span></span>
            <ClickableLink href='https://www.grasp.upenn.edu/research-groups/perception-action-learning-group/'>
            {' '}PAL Group
            </ClickableLink> and <span></span>
            <ClickableLink href='https://www.csail.mit.edu/research/robust-robotics-group'>
            {' '}MIT CSAIL
            </ClickableLink>, making world models object-centric and hierarchical (e.g. for model-based RL).
            At the same time, I was perfecting my craft as a code monkey @ <span></span>
            <ClickableLink href='https://www.tesla.com/careers/internships'>Tesla</ClickableLink>.</div>
        <div className='h-4'></div>
        <div className='font-medium text-lg'>I'm also an amateur <span></span>
            <ClickableLink href='https://youtu.be/4hsJZVQXEQY'>pianist</ClickableLink> and <span></span>
            violinist. During concert season, I'm coached by <span></span>
            <ClickableLink href='https://music.sas.upenn.edu/ensembles/penn-chamber'>Penn Chamber</ClickableLink> 
            <span></span>'s director Thomas Kraines with my piano quintet, and led by Maestro Thomas Hong as a concertmaster for the <span></span>
            <ClickableLink href='https://music.sas.upenn.edu/ensembles/penn-symphony-orchestra'>Penn Symphony Orchestra</ClickableLink>.</div>
        <div className='h-4'></div>
        <div className='font-medium text-lg'>Balancing bits and beats. If that sounds intriguing, let's connect.</div>
        <div className='h-8'></div>

        <div className='h-8 flex flex-row'>
            <ClickableLink href={Math.random() > 0.5 ? '/resume_quanta.pdf' : '/resume_swea.pdf'} ><div className='font-semibold text-lg'>Resume</div></ClickableLink>
            <div className='w-4'></div>
            <div className='font-semibold text-lg'>|</div>
            <div className='w-4'></div>
            <ClickableLink href='https://www.linkedin.com/in/ethan-r-yu/' ><div className='font-semibold text-lg'>LinkedIn</div></ClickableLink>

            <div className='w-4'></div>
            <div className='font-semibold text-lg'>|</div>
            <div className='w-4'></div>
            <ClickableLink href='mailto:ethanyu@upenn.edu' ><div className='font-semibold text-lg'>Email</div></ClickableLink>
        </div>
    </div>;
};

const ClickableLink = ({ href, children }) => {
    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className="underline underline-offset-4 hover:no-underline"
        >
            {children}
        </a>
    );
}

export default Home;
