// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="p-4 sticky top-0">
      <ul className="flex justify-end space-x-10">
        <li>
          <Link to="/" className="text-primary text-l hover:text-secondary font-bold">
            Home
          </Link>
        </li>
        <li>
          <Link to="/music" className="text-primary text-l hover:text-secondary font-bold">
            Music
          </Link>
        </li>
        <li>
          <Link to="/fun" className="text-primary text-l hover:text-secondary font-bold">
            Fun
          </Link>
        </li>
        <li>
          <Link to="/projects" className="text-primary text-l hover:text-secondary font-bold">
            Projects
          </Link>
        </li>
        <li>
            <Link to="/robotics" className="text-primary text-l hover:text-secondary font-bold">
            Robotics
            </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
