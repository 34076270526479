//import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Music from './components/Music';
import Fun from './components/Fun';
import Projects from './components/Projects';
import Robotics from './components/Robotics';
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <Router>
    <div className='max-w-5xl mx-auto px-4 sm:px-6 lg:px-8'>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/music" element={<Music />} />
        <Route path="/fun" element={<Fun />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/robotics" element={<Robotics />} />
      </Routes>
      <Analytics />
    </div>
  </Router>
  );
}

export default App;
