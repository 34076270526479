// src/components/Music.js
import React from 'react';

const Music = () => {
  return <div className="text-balance font-black font-sans">
  WIP. Come back soon!
</div>;
};

export default Music;
